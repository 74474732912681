// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'https://pegasusmusicapp.com/pegasusmusicadmin/', // new
  staticUrl: 'https://pegasusmusicapp.com/pegasusmusicadmin/assets/images/logo_pic.png',
  firebase: {
    apiKey: "AIzaSyA6H1ny89AKmBGwNBiIhnS5H5kJZ7ykULw",
    authDomain: "durisimo-music.firebaseapp.com",
    databaseURL: "https://durisimo-music-default-rtdb.firebaseio.com",
    projectId: "durisimo-music",
    storageBucket: "durisimo-music.firebasestorage.app",
    messagingSenderId: "419750473065",
    appId: "1:419750473065:web:5d5322c6d2d8ab8958e1f8",
    measurementId: "G-YY2WQKESMQ"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
