import { Component } from '@angular/core';
import { EventEmiterService } from './shared/event.emmiter.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'pegasusmusic';
  showPlayer = false;

  constructor(private eventEmiterService: EventEmiterService) {
    this.eventEmiterService.videoPlayerLoadedCheck$.subscribe(data => {
      this.showPlayer = data;
    })
  }
}
